import React from 'react';

interface Props {
    title: any;
    className?: string;
}
const PageTitle = (props: Props) => {

    return (
        <React.Fragment>
            <div id="PageTitle" className={`millik text-2xl xl:text-3xl capitalize ${props.className}`}>
                {props.title}
            </div>
        </React.Fragment>
    );
}

export default PageTitle;