import React from 'react';
import { helpers, types } from 'helpers';

const Store = React.createContext({} as types.StoreInterface);

export const StoreProvider = (props: {
    children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal | null | undefined;
}) => {

    const szo: types.UserSso = helpers.isJson(helpers.getStorageJson('auth').sso) ? JSON.parse(helpers.getStorageJson('auth').sso) : {};
    const uzer: types.User = helpers.isJson(helpers.getStorageJson('auth').user) ? JSON.parse(helpers.getStorageJson('auth').user) : {};
    const tokin = helpers.isJson(helpers.getStorageJson('auth').token) ? JSON.parse(helpers.getStorageJson('auth').token) : '';
    const settings = helpers.isJson(helpers.getStorageJson('data').settings) ? JSON.parse(helpers.getStorageJson('data').settings) : {};
    const countries = helpers.isJson(helpers.getStorageJson('data').countries) ? JSON.parse(helpers.getStorageJson('data').countries) : [];

    const [sso, setSso] = React.useState<types.UserSso>(szo);
    const [user, setUser] = React.useState<types.User>(uzer);
    const [token, setToken] = React.useState(tokin);
    // eslint-disable-next-line
    const [_utils, _] = React.useState({
        limit: 25,
        loading: {
            primary: '',
            spinning: false,
            secondary: '',
        }
    });
    const [_data, setData] = React.useState({
        meCache: helpers.randCode().toLowerCase(),
        settings,
        countries: Object.values(countries) as any[],
    });

    const _store = { _auth: { sso, user, token }, _utils, _data };

    const _setSso = (value: types.UserSso) => {
        const auth = helpers.getStorageJson('auth') || {};
        const usar: types.UserSso = helpers.isJson(auth.sso) ? JSON.parse(auth.sso) : {};
        const newData = { ...usar, ...value };
        auth['sso'] = JSON.stringify(newData);
        setSso(newData);
        helpers.setStorageJson('auth', auth);
    }

    const _setUser = (value: types.User) => {
        const auth = helpers.getStorageJson('auth') || {};
        const usar: types.User = helpers.isJson(auth.user) ? JSON.parse(auth.user) : {};
        const newData = { ...usar, ...value };
        auth['user'] = JSON.stringify(newData);
        setUser(newData);
        helpers.setStorageJson('auth', auth);
    }

    const _setToken = (value: string) => {
        const auth = helpers.getStorageJson('auth') || {};
        auth['token'] = JSON.stringify(value);
        setToken(value);
        helpers.setStorageJson('auth', auth);
    }

    const _setAuth = (value: { user: types.User; token: string }) => {
        _setUser(value.user);
        _setToken(value.token);
    }

    const _setData = (payload: { key: 'settings' | 'countries', value: any }) => {
        const { key, value } = payload;
        const dada = helpers.getStorageJson('data') || {};
        const usar = helpers.isJson(dada[key]) ? JSON.parse(dada[key]) : {};
        const newData = helpers.isJson(value) ? { ...usar, ...value } : value;
        dada[key] = JSON.stringify(newData);
        setData(sd => ({ ...sd, [key]: newData }));
        helpers.setStorageJson('data', dada);
    }

    return (
        <Store.Provider value={{ _store, _setSso, _setAuth, _setUser, _setToken, _setData }}>
            {props.children}
        </Store.Provider>
    );

}

export default Store;