import React from 'react';

// ::: app pages
const Users = React.lazy(() => import('pages/Users'));
const Roles = React.lazy(() => import('pages/Users/Roles'));
const Senders = React.lazy(() => import('pages/Senders'));
const Reports = React.lazy(() => import('pages/Reports'));
const Settings = React.lazy(() => import('pages/Settings'));
const Messages = React.lazy(() => import('pages/Reports/Messages'));
const Dashboard = React.lazy(() => import('pages/Dashboard'));
const Developers = React.lazy(() => import('pages/Developers'));
const WhiteLabels = React.lazy(() => import('pages/WhiteLabels'));


// ::: app routes
const routes = [
    { path: `/`, element: Dashboard, },
    { path: `/users`, element: Users, },
    { path: `/senders`, element: Senders, },
    { path: `/messages`, element: Messages, },
    { path: `/settings`, element: Settings, },
    { path: `/reports/*`, element: Reports, },
    { path: `/developers`, element: Developers, },
    { path: `/whitelabels`, element: WhiteLabels, },
    { path: `/team/roles`, element: Roles, },
    { path: `/team/members`, element: Users, },

    { path: `*`, element: Dashboard, },
];

export default routes;