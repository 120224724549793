import React from 'react';

interface Props {
    label: string;
    status: number;

    onClick?: () => void;
}
const Status = ({ label, status, onClick }: Props) => {
    const color = {
        0: 'info',
        1: 'success',
        2: 'danger',
        3: 'danger',
        4: 'warning',
    }
    const kolor = color[status as keyof typeof color];

    return (
        <React.Fragment>
            <div
                id="Status"
                className={`${kolor} rounded-lg inline-flex items-center py-1 px-3 text-xs font-semibold capitalize ${onClick ? 'cursor-pointer' : ''}`}
                onClick={() => onClick && onClick()}
            >
                <div className={`h-[4px] w-[4px] rounded-full mr-1 dot ${kolor}`} />
                {label}
            </div>
        </React.Fragment>
    );

};

export default Status;