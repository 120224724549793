import { Avatar, Tag } from 'antd';
import { MaterialIcon, Status } from 'components';
import { helpers, types } from 'helpers';
import ReactCountryFlag from 'react-country-flag';


const UserDisplay = (user: types.User) => (
    <div>
        <div>{user?.name}</div>
        <div className="text-gray-500 text-xs mt-1">{helpers.format.phoneNumber(user?.phone)}</div>
    </div>
);

export const WhiteLabels = {
    heading: ['', 'Name', 'Credits', 'Status', 'Date'],
    data: (data: types.WhiteLabel[]) => data.map(row => {
        return {
            id: row.uuid,
            logo: <Avatar src={row.logo_link} size={'large'} />,
            name: row.name,
            credits: helpers.format.currency(row.credits),
            status: <Status status={row.status} label={row.status ? 'Enabled' : 'Disabled'} />,
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const Developers = {
    heading: ['Name', 'Description', 'Credentials', 'Status', 'Date'],
    data: (data: types.Developer[]) => data.map(row => {
        return {
            id: row.uuid,
            name: {
                width: 20,
                value: row.name,
            },
            description: {
                width: 30,
                value: row.description || 'N/A',
            },
            credentials: (
                <>
                    <div
                        className="px-2 py-1 rounded-xl bg-gray-100 hover:bg-gray-200 transi truncate cursor-pointer flex items-center gap-1 text-xs"
                        onClick={() => helpers.copyToClipboard(`${row.credentials.key}.${row.credentials.secret}`)}
                    >
                        <MaterialIcon name="content_copy" className="text-xl" /> {row.credentials.key}.{row.credentials.secret}
                    </div>
                </>
            ),
            status: <Status status={row.status} label={row.status ? 'Enabled' : 'Disabled'} />,
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const Senders = {
    heading: ['User', 'Name', 'Description', 'Status', 'Date'],
    data: (data: types.Sender[]) => data.map(row => {
        const status = ['Pending', 'Approved', 'Rejected', 'Deleted'];
        return {
            id: row.uuid,
            user: {
                width: 12,
                value: UserDisplay(row.user),
            },
            name: {
                width: 13,
                value: row.name,
            },
            description: (row.description || 'N/A'),
            status: {
                width: 15,
                value: (
                    <div>
                        <Status status={row.status} label={status[row.status]} />
                        {row.messages.length > 0 && (
                            <div className="text-gray-500 text-xs mt-1">{row.messages.map(m => m.status_text).join(', ')}</div>
                        )}
                    </div>
                ),
            },
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const Templates = {
    heading: ['Name', 'Message', 'Date'],
    data: (data: types.Template[]) => data.map(row => {
        return {
            id: row.uuid,
            name: {
                width: 14,
                value: row.name,
            },
            message: {
                value: row.message || 'N/A',
            },
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const Contacts = {
    heading: ['Contact', 'Group(s)', 'Customs'],
    data: (data: types.Contact[], groups: types.ContactGroup[]) => data.map(row => {
        return {
            id: row.uuid,
            name: (
                <>
                    <ReactCountryFlag countryCode={row.country.code} /> <b>{row.number}</b> <br />
                    <span className="text-muted">{row.first_name} {row.last_name}</span>
                </>
            ),
            groups: groups.filter(g => row.groups.split(',').includes(g.uuid)).map(g => g.name).join(', '),
            customs: (
                <>
                    {(row.custom1 || row.custom2 || row.custom3 || row.custom4) ? (
                        <span className="tags">
                            {row.custom1 && (<Tag>{row.custom1}</Tag>)}
                            {row.custom2 && (<Tag>{row.custom2}</Tag>)}
                            {row.custom3 && (<Tag>{row.custom3}</Tag>)}
                            {row.custom4 && (<Tag>{row.custom4}</Tag>)}
                        </span>
                    ) : <span className="text-muted">N/A</span>}
                </>
            ),
        };
    })
};

export const Messages = {
    heading: ['User', 'Sender', 'Message', 'Credits', 'Status', 'Date'],
    data: (data: types.Message[]) => data.map(row => {
        const status = ['In Queue', 'Processed', 'Out of Credit', 'Deleted', 'Unregistered Sender'];
        return {
            id: row.uuid,
            user: {
                width: 12,
                value: UserDisplay(row.user),
            },
            sender: {
                width: 13,
                value: (
                    <div>
                        <div>{row.sender}</div>
                        <div className="text-gray-500 text-xs mt-1">{helpers.format.number(row.recipients_no, 0)} recipient{row.recipients_no > 1 ? 's' : ''}</div>
                    </div>
                ),
            },
            message: {
                width: 35,
                value: row.message,
            },
            credits: {
                width: 10,
                value: helpers.format.currency(row.credits),
            },
            status: {
                width: 15,
                value: <Status status={row.status} label={status[row.status]} />,
            },
            date: {
                width: 15,
                value: (
                    <>
                        {helpers.format.date(row.crdate)}
                        {row.type === 1 && <div className="text-gray-500">@{helpers.format.date(row.schedule.date + ' ' + row.schedule.time)}</div>}
                    </>
                ),
            },
        };
    })
};

export const MessagesDelivery = {
    heading: ['Gateway', 'Number', 'Credits', 'Status', 'Date'],
    data: (data: types.MessageDelivery[]) => data.map(row => {
        return {
            id: row.uuid,
            gateway: {
                width: 7,
                value: row.gateway || 'N/A',
            },
            number: {
                width: 12,
                value: row.number,
            },
            credits: {
                width: 12,
                value: helpers.format.currency(row.credits),
            },
            status: {
                width: 13,
                value: (
                    <div className="flex items-center gap-1">
                        {row.status === 1 ? <MaterialIcon name="check_circle" className="text-lg primary fill" /> : <MaterialIcon name="cancel" className="text-lg danger fill" />}
                        {row.status_text}
                    </div>
                )
            },
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const Payments = {
    heading: ['Token', 'Gateway', 'User', 'Description', 'Amount', 'Status', 'Date'],
    data: (data: types.Payment[]) => data.map(row => {
        const status = ['Pending', 'Succesful', 'Failed'];
        return {
            id: row.uuid,
            token: {
                width: 20,
                value: <Status status={row.status} label={row.token} onClick={() => helpers.copyToClipboard(row.token)} />
            },
            gateway: {
                width: 10,
                value: helpers.ucFirst(row.gateway),
            },
            user: {
                width: 12,
                value: UserDisplay(row.user),
            },
            description: {
                width: 21,
                value: row.description,
            },
            amount: {
                width: 12,
                value: helpers.format.currency(row.amount),
            },
            status: {
                width: 10,
                value: <Status status={row.status} label={status[row.status]} />,
            },
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const MessageSummary = {
    heading: ['User', 'Credits', 'Messages', 'Sent', 'Failed'],
    data: (data: types.MessageSummary[]) => data.map(row => {
        return {
            id: row.user.uuid,
            user: {
                width: 12,
                value: UserDisplay(row.user),
            },
            credits: {
                width: 21,
                value: helpers.format.currency(row.credits),
            },
            messages: {
                width: 12,
                value: helpers.format.number(row.messages, 0),
            },
            sent: {
                width: 12,
                value: helpers.format.number(row.sent, 0),
            },
            failed: {
                width: 12,
                value: helpers.format.number(row.failed, 0),
            },
        };
    })
};

export const ReferralReport = {
    heading: ['User', 'Count'],
    data: (data: types.ReferralReport[]) => data.map(row => {
        return {
            id: row.user,
            user: {
                width: 50,
                value: row.user,
            },
            messages: {
                width: 50,
                value: helpers.format.number(row.count, 0),
            },
        };
    })
};

export const User = {
    heading: ['User', 'Email', 'Credits', 'Access', 'Prefs', 'Status', 'Date'],
    data: (data: types.User[]) => data.map(row => {
        const status = ['Pending', 'Active', 'Suspended'];
        return {
            id: row.uuid,
            user: {
                width: 12,
                value: UserDisplay(row),
            },
            email: {
                width: 14,
                value: row.email,
            },
            credits: {
                width: 12,
                value: helpers.format.currency(row.credits),
            },
            access: {
                width: 12,
                value: row.access?.name || 'Odogwu',
            },
            prefs: {
                width: 21,
                value: (
                    <div>
                        {Object.keys((row.preferences || [])).map(pref => (
                            <div key={pref}>•⁠ {pref}: {typeof row.preferences[pref] === 'object' ? JSON.stringify(row.preferences[pref]) : row.preferences[pref]}</div>
                        ))}
                    </div>
                ),
            },
            status: {
                width: 10,
                value: <Status status={row.status} label={status[row.status]} />,
            },
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const Role = {
    heading: ['Name', 'Status', 'Date'],
    data: (data: types.Role[]) => data.map(row => {
        const status = ['Inactive', 'Active'];
        return {
            id: row.uuid,
            name: row.name,
            status: <Status status={row.status} label={status[row.status]} />,
            date: helpers.format.date(row.crdate),
        };
    })
};