export interface AxiusResponse {
    data: any;
    status: number;
    message: string;
}

export interface ApiTrigger {
    data: any;
    error: any;
    message: string;
}

export interface StoreInterface {
    _store: {
        _auth: {
            sso: UserSso;
            user: User;
            token: string;
        },
        _utils: {
            limit: number;
            loading: {
                primary: string;
                spinning: boolean;
                secondary: string;
            }
        },
        _data: {
            settings: {
                gh: any;
                ng: any;
                global: any;
            };
            countries: {
                iso: number;
                code: string;
                name: string;
                decimal: number;
                phone_length: number;
            }[];
            meCache: string;
        };
    },

    _setSso: (value: UserSso) => void;
    _setUser: (value: User) => void;
    _setAuth: (value: { user: User, token: string }) => void;
    _setToken: (value: string) => void;
    _setData: (payload: { key: 'settings' | 'countries' | 'meCache', value: any }) => void;
    // _setUtils: (payload: { key: 'loading' | 'env', value: any }) => void;
}
export interface User {
    id: string;
    sso: string;
    uuid: string;
    name: string;
    email: string;
    country: Country;
    phone: string;
    password: string;
    compliance: {
        email: boolean;
        phone_number: boolean;
        document: {
            type: string;
            file: string;
            status: boolean;
        };
    };
    credits: number;
    preferences: any;
    crdate: string;
    update: string;
    undate: string;
    status: 0 | 1 | 2;
    admin: 0 | 1;
    access: Role;
}

export interface UserSso {
    id: string;
    name: string;
    email: string;
    country: string;
    phone_number: string;
    password: string;
    avatar: string;
    avatar_link: string;
    compliance: {
        email: boolean;
        phone_number: boolean;
        document: {
            type: string;
            file: string;
            status: boolean;
        };
    };
    preferences: any;
    created_at: string;
    updated_at: string;
    type: UserTypes;
    status: UserStatus;
}

export enum UserTypes {
    USER = 'USER',
    ADMIN = 'ADMIN',
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
}

export interface Filter {
    key: string;
    type: 'text' | 'number' | 'select' | 'date_range' | 'senders' | 'users';
    value?: string;
    label?: string;
    options?: string[] | { label: string; value: string; }[];
    placeholder?: string;
}

export interface DataMeta {
    limit: number;
    next?: string;
    page: number;
    previous?: string;
    total: number;
}

export enum Folders {
    users = 'users',
    whitelabels = 'whitelabels',
}

export const ContactFields = [
    { name: 'Phone number', code: 'NUMBER', },
    { name: 'First name', code: 'FIRST_NAME', },
    { name: 'Last name', code: 'LAST_NAME', },
    { name: 'Custom 1', code: 'CUSTOM1', },
    { name: 'Custom 2', code: 'CUSTOM2', },
    { name: 'Custom 3', code: 'CUSTOM3', },
    { name: 'Custom 4', code: 'CUSTOM4', },
]

export interface WhiteLabel {
    commissions: number;
    crdate: string;
    credits: number;
    id: number;
    logo: string;
    logo_link: string;
    name: string;
    preferences: {
        per_sms: number;
        primary_color: string;
    };
    status: 0 | 1;
    undate: string;
    update: string;
    user: User;
    uuid: string;
}

export interface Developer {
    id: number;
    uuid: string;
    user: User;
    name: string;
    description: string;
    credentials: {
        key: string;
        secret: string;
    };
    callback: string;
    status: 0 | 1;
    crdate: string;
    update: string;
}

export interface Sender {
    id: number;
    uuid: string;
    user: User;
    name: string;
    description: string;
    options: {
        nigeria: 0 | 1;
    };
    status: 0 | 1 | 3;
    messages: {
        status_text: string;
    }[];
    crdate: string;
    update: string;
}

export interface Template {
    id: number;
    uuid: string;
    user: User;
    name: string;
    message: string;
    crdate: string;
    update: string;
}

export interface Contact {
    id: number;
    uuid: string;
    user: string;
    number: string;
    groups: string;
    last_name: string;
    first_name: string;
    custom1: string;
    custom2: string;
    custom3: string;
    custom4: string;
    country: Country;
    crdate: string;
    update: string;
}

export interface ContactGroup {
    id: number;
    uuid: string;
    user: string;
    name: string;
    crdate: string;
    update: string;
    contacts_count: number;
}

export interface Country {
    iso: number;
    code: string;
    name: string;
    decimal: number;
    currency: string;
    phone_length: number;
}

export interface Message {
    id: number;
    uuid: string;
    user: User;
    type: 0 | 1;
    sender: string;
    credits: number;
    message: string;
    recipients: string;
    status: 0 | 1 | 2;
    dynamic: 0 | 1;
    estimate: {
        characters: number;
        countries: any;
        credits: number;
        numbers: number;
        pages: number;
        per_sms: number;
        per_sms_out: number;
    };
    schedule: {
        time: string;
        date: string;
    };
    recipients_no: number;
    crdate: string;
    update: string;
}

export interface MessageDelivery {
    id: number;
    uuid: string;
    user: User;
    sender: string;
    credits: number;
    message: string;
    number: string;
    status: 0 | 1 | 2;
    dynamic: 0 | 1;
    status_text: string;
    estimate: {
        characters: number;
        countries: any;
        credits: number;
        numbers: number;
        pages: number;
        per_sms: number;
        per_sms_out: number;
    };
    gateway: string;
    gateway_response: any;
    recipients_no: number;
    crdate: string;
    update: string;
}

export interface MessageSummary {
    credits: number;
    failed: number;
    messages: number;
    sent: number;
    user: User;
}

export interface Payment {
    id: number;
    uuid: string;
    user: User;
    amount: number;
    country: string;
    description: string;
    status: 0 | 1 | 2;
    gateway: string;
    token: string;
    crdate: string;
    update: string;
}

export interface ReferralReport {
    user: string;
    count: number;
}

export interface Product {
    icon: string;
    name: string;
    code: string;
    description: string;
    url?: {
        local: string;
        staging: string;
        production: string;
    };
    requires_document?: boolean;
    type?: UserTypes;
}

export interface Role {
    id: number;
    uuid: string;
    name: string;
    access: string;
    status: 0 | 1;
    crdate: string;
    update: string;
}